import React, {FC} from 'react';

import {getLog} from '../../log';
import {Document} from '../../model';
import {realHost} from '../../utils/host';
import List from '../common/List';
import ListItem from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import PageTitle from '../common/PageTitle';
import SectionTitle from '../common/SectionTitle';
import {DocumentsCommercial} from './DocumentsCommercial';

const log = getLog('DocumentsMain');

const legacyGuides: Document[] = [
  {name: 'Bulk SMS Implementation guide (SOAP)', url: 'soap/cpa-api-guide.html'},
  {name: 'Bulk SMS REST API v1', url: 'rest/bulk.html'},
  {name: 'Bulk SMS Callback REST API v1', url: 'rest/bulk-callback.html'},
];

export const DocumentsMainFc: FC = (props) => {
  log.debug('render', props);

  const host = realHost();

  return (
    <Page>
      <PageTitle title="Documents" />

      <PageSubtitle subtitle="API guides" />
      <List>
        <ListItem>
          <a href={`${host}/documents/developer/index.html`} target="_blank">
            Bulk Messaging Implementation Guide
          </a>
        </ListItem>
      </List>

      <SectionTitle>Legacy guides</SectionTitle>
      <List>
        {legacyGuides.map(({name, url}) => (
          <ListItem>
            <a href={`${host}/documents/api/${url}`} target="_blank">
              {name}
            </a>
          </ListItem>
        ))}
      </List>

      <DocumentsCommercial />
    </Page>
  );
};
