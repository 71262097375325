import React, {FC, PropsWithChildren} from 'react';

const Loading: FC<PropsWithChildren> = (props) => {
  return (
    <div className="spinner spinner--lg">
      <div className="spinner__blade spinner__blade--0" />
      <div className="spinner__blade spinner__blade--1" />
      <div className="spinner__blade spinner__blade--2" />
      <div className="spinner__blade spinner__blade--3" />
      <div className="spinner__blade spinner__blade--4" />
      <div className="spinner__blade spinner__blade--5" />
      <div className="spinner__blade spinner__blade--6" />
      <div className="spinner__blade spinner__blade--7" />
      <div className="spinner__blade spinner__blade--8" />
      <div className="spinner__blade spinner__blade--9" />
      <div className="spinner__blade spinner__blade--10" />
      <div className="spinner__blade spinner__blade--11" />
      <div className="spinner__blade spinner__blade--12" />
      <div className="spinner__blade spinner__blade--13" />
    </div>
  );
};

export default Loading;
