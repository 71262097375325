query accessNumberHistory($customerId: ID) {
  accessNumberHistory(customerId: $customerId) {
    accessNumber
    accessNumberRange {
      from
      to
      count
    }
    productHistory {
      id
      customerId
      contractId
      startDate
      endDate
    }
  }
}
