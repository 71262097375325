import {OperationVariables, gql, useQuery} from '@apollo/client';
import {format} from '@telia/cpa-web-common';
import {SmppSessionsWithProductDetails} from '@telia/cpa-web-common/dist/model';
import {Button, FullWidthTable} from '@telia/styleguide';
import React, {FC, useMemo, useState} from 'react';

import unbindSmppSessionMutation from '../../graphql/mutation/unbindSmppSession.graphql';
import smppSessionWithProductDetailsQuery from '../../graphql/query/smppSessionsWithProductDetails.graphql';

import {useCustomerName} from '../../hooks/useCustomerName';
import {useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useUser} from '../../hooks/useUser';
import {useUserCustomer} from '../../hooks/useUserCustomer';
import {getLog} from '../../log';
import {CUSTOMER_TECHNICAL_MANAGE} from '../../permissions';
import Loading from '../Loading';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('SmppConnectionsTable', 'INFO');

const {THead, Tr, Th, ThSortable, TBody, Td} = FullWidthTable;

interface SmppConnectionsTableProps {
  customerId?: string;
}

interface SmppSessionsWithProductDetailsQuery {
  smppSessionsWithProductDetails: SmppSessionsWithProductDetails[];
}

const SmppConnectionsTable: FC<SmppConnectionsTableProps> = ({customerId}) => {
  const formState = useFormState({
    isEditing: true,
    useUrlParams: false,
  });
  const {entity, onChange} = formState;
  const [showForm, setShowForm] = useState(false);
  const {hasPermission} = useUser();
  const {customerName} = useUserCustomer();
  const {getCustomerName} = useCustomerName();
  const customerNameById = getCustomerName(customerId);

  const smppSessionsWithProductDetailsOperationVariables: OperationVariables = {
    variables: {customerId},
    onCompleted: (data: SmppSessionsWithProductDetailsQuery) => {
      if (data.smppSessionsWithProductDetails && data.smppSessionsWithProductDetails.length > 0) {
        setShowForm(true);
      }
    },
  };
  const {loading, error, data, refetch} = useQuery<SmppSessionsWithProductDetailsQuery>(
    gql(smppSessionWithProductDetailsQuery),
    smppSessionsWithProductDetailsOperationVariables
  );

  const unbindSmppSession = useMutationWrap<{}, {session: SmppSessionsWithProductDetails}>(
    gql(unbindSmppSessionMutation)
  );
  const onUnbind = (session: SmppSessionsWithProductDetails) => {
    unbindSmppSession({
      loadingText: 'Disconnecting...',
      successText: 'Session disconnected',
      variables: {
        session,
      },
      update: () => {
        refetch(smppSessionsWithProductDetailsOperationVariables);
      },
    });
  };

  const [expandedCells, setExpandedCells] = useState<{[key: string]: boolean}>({});

  const handleCellClick = (rowIndex: number, columnId: string) => {
    const cellKey = `${rowIndex}-${columnId}`;
    setExpandedCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  const truncateText = (text: string, length: number, isExpanded: boolean) => {
    if (isExpanded) {
      return <strong>{text}</strong>;
    }
    if (!text) {
      return '';
    }
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const filteredData = useMemo(() => {
    if (!data || !data.smppSessionsWithProductDetails) {
      return [];
    }

    const fromDateTime = entity.fromDate ? new Date(`${entity.fromDate}T${entity.fromTime || '00:00:00'}`) : null;
    const toDateTime = entity.toDate ? new Date(`${entity.toDate}T${entity.toTime || '23:59:59'}`) : null;

    return data.smppSessionsWithProductDetails.filter((item: SmppSessionsWithProductDetails) => {
      const sessionDate = new Date(item.sessionCreatedAt);
      const productIdFilter = entity.productId ? String(entity.productId) : '';
      const customerIdFilter = entity.customerId ? String(entity.customerId) : '';

      const filterConditions = [
        !productIdFilter || item.productId.includes(productIdFilter),
        !customerIdFilter || item.customerId.includes(customerIdFilter),
        !fromDateTime || sessionDate >= fromDateTime,
        !toDateTime || sessionDate <= toDateTime,
      ];

      return filterConditions.every((condition) => condition);
    });
  }, [data, entity]);

  const [sortedTableContent, getThSortableProps] = FullWidthTable.useThSortable<SmppSessionsWithProductDetails>(
    filteredData,
    3,
    [],
    {}
  );

  if (loading) return <Loading />;
  if (error) return <p>Error: {error.message}</p>;

  log.debug('sortedTableContent', {sortedTableContent});

  const displayedCustomerName = customerName || customerNameById;
  const isManagement = !customerId;

  return (
    <div>
      <div className={'form--column--margin--normal'}>
        {showForm && (
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormRow>
              <FormColumn>
                {isManagement ? (
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'customerId'}
                    label="Customer ID"
                    type={FieldTypes.input}
                    onChange={onChange('customerId')}
                  />
                ) : (
                  <Field label={'Customer'} value={displayedCustomerName} isDisabled={true} />
                )}
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'productId'}
                  label="Product ID"
                  type={FieldTypes.input}
                  onChange={onChange('productId')}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'fromDate'}
                  label="From date"
                  type={FieldTypes.date}
                  defaultValue={entity.inheritsEndDate ? (entity.toDate as string) : 'no end'}
                />
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'fromTime'}
                  label="From time"
                  type={FieldTypes.time}
                  defaultValue={'End of day'}
                />
              </FormColumn>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'toDate'}
                  label="To date"
                  type={FieldTypes.date}
                  defaultValue={entity.inheritsEndDate ? (entity.toDate as string) : 'no end'}
                />
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'toTime'}
                  label="To time"
                  type={FieldTypes.time}
                  defaultValue={'End of day'}
                />
              </FormColumn>
            </FormRow>
          </Form>
        )}
      </div>
      {sortedTableContent && sortedTableContent.length > 0 ? (
        <FullWidthTable clickable={false}>
          <THead>
            <Tr>
              {isManagement && <ThSortable {...getThSortableProps('customerId')}>CustomerID</ThSortable>}
              <ThSortable {...getThSortableProps('productId')}>ServiceID</ThSortable>
              <ThSortable {...getThSortableProps('sessionCreatedAt')}>Start Date</ThSortable>
              <ThSortable {...getThSortableProps('apiCredentials')}>Username</ThSortable>
              {!customerId && <ThSortable {...getThSortableProps('instanceId')}>ECS Instance ID</ThSortable>}
              <ThSortable {...getThSortableProps('bindType')}>Session Type</ThSortable>
              {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && <Th></Th>}
            </Tr>
          </THead>

          <TBody>
            {sortedTableContent.map(
              ({sessionId, customerId, productId, sessionCreatedAt, apiCredentials, instanceId, bindType}, i) => (
                <Tr key={i}>
                  {isManagement && <Td>{customerId}</Td>}
                  <Td>{productId}</Td>

                  <Td onClick={() => handleCellClick(i, 'sessionCreatedAt')}>
                    {truncateText(format.dateTime(sessionCreatedAt), 11, !!expandedCells[`${i}-sessionCreatedAt`])}
                  </Td>
                  <Td onClick={() => handleCellClick(i, 'apiCredentials')}>
                    {truncateText(apiCredentials, 11, !!expandedCells[`${i}-apiCredentials`])}
                  </Td>
                  {isManagement && (
                    <Td onClick={() => handleCellClick(i, 'instanceId')}>
                      {truncateText(instanceId, 11, !!expandedCells[`${i}-instanceId`])}
                    </Td>
                  )}

                  <Td>{bindType}</Td>
                  {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && (
                    <Td>
                      <Button
                        text="Disconnect"
                        kind="negative"
                        onClick={() =>
                          onUnbind({
                            sessionId,
                            customerId,
                            productId,
                            sessionCreatedAt,
                            apiCredentials,
                            instanceId,
                            bindType,
                          })
                        }
                      />
                    </Td>
                  )}
                </Tr>
              )
            )}
          </TBody>
        </FullWidthTable>
      ) : (
        <InformationLineFc>No SMPP connections found</InformationLineFc>
      )}
    </div>
  );
};

export default SmppConnectionsTable;
