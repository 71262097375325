import {gql, useQuery} from '@apollo/client';
import {getIsProtocolCredentialsRequired} from '@telia/cpa-web-common/dist/utils';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import generateSecretMutation from '../../graphql/mutation/generateSecret.graphql';
import saveProductTechnicalMutation from '../../graphql/mutation/saveProductTechnical.graphql';
import productTechnicalQuery from '../../graphql/query/productTechnical.graphql';

import {FormState, FormStateOptions, asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {
  ApiCredentials,
  ApolloObject,
  DefaultCharset,
  DeliveryEndpoint,
  EndpointProtocol,
  ID,
  ProductTechnical,
  ProductTechnicalInput,
  SmppApiConfig,
  WithId,
} from '../../model';
import {CUSTOMER_TECHNICAL_MANAGE, PRODUCT_FEATURES_MANAGE, TELIA_USERS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';
import {ServiceTechnicalFeatures} from './ServiceTechnicalFeatures';

const log = getLog('ProductTechnical', 'INFO');

const noCallbackPlaceholder = 'No callback';

interface ServiceTechnicalQuery {
  productTechnical: WithId<
    ApolloObject<{
      endpoints: ApolloObject<{
        mo: ApolloObject<DeliveryEndpoint>;
        dr: ApolloObject<DeliveryEndpoint>;
      }>;
      apiCredentials: ApolloObject<ApiCredentials>;
      smppApiConfig: ApolloObject<SmppApiConfig>;
    }>
  >;
}

interface ServiceTechnicalProps {
  productTypeId: ID;
}

export const ServiceTechnical: FunctionComponent<ServiceTechnicalProps> = ({productTypeId}) => {
  const [isSecretValidMessage, setIsSecretValidMessage] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const {contractId, customerId, productId} = useParams<{contractId: ID; customerId: ID; productId: ID}>() as {
    contractId: ID;
    customerId: ID;
    productId: ID;
  };

  const {
    hasProductTechnicalApiCredentials,
    hasProductTechnicalDr,
    hasProductTechnicalMo,
    getProductTypeSmppApiDefaultConfig,
  } = useProductTypes();
  const {showModal} = useModal();
  const smppApiDefaultConfig = getProductTypeSmppApiDefaultConfig(productTypeId);
  const {
    loading,
    error: productTechnicalQueryError,
    data: {productTechnical} = {},
  } = useQuery<ServiceTechnicalQuery>(gql(productTechnicalQuery), {
    variables: {contractId, customerId, productId},
  });

  const initialEntity = productTechnical
    ? asEntity(productTechnical)
    : {
        __typename: 'ProductTechnical',
        endpoints: {
          __typename: 'DeliveryEndpoints',
          mo: {
            __typename: 'DeliveryEndpoint',
          },
          dr: {
            __typename: 'DeliveryEndpoint',
          },
        },
        apiCredentials: {
          __typename: 'Credentials',
        },
        smppApiConfig: {
          __typename: 'SmppApiConfig',
        },
      };

  //  TODO: confirm not loading from ApolloCache
  // const formFragmentOptions: UseApolloCacheEntityProps = {
  //   fragment: productTechnicalFragment,
  //   fragmentName: 'productTechnical',
  //   entityId: productId,
  //   newEntity: initialEntity,
  // };
  // const formStateOptions = useApolloCacheEntity(formFragmentOptions);
  const formStateOptions: FormStateOptions = {initialEntity, isEditing: false};
  const formState = useFormState(formStateOptions);

  const saveProductTechnical = useMutationWrap<
    {saveProductTechnical: ProductTechnical},
    {customerId: ID; contractId: ID; productId: ID; productTechnical: ProductTechnicalInput}
  >(gql(saveProductTechnicalMutation));

  const onSave = async () => {
    setIsSaving(true);

    const technical = formState.entityAs<ProductTechnical>();
    delete technical.apiCredentials; //  NOTE: do not send apiCredentials since shouldn't be modified at all
    delete technical.id; //  NOTE: do not send id since is only for client apollo cache
    if (technical.protocol !== EndpointProtocol.SMPP) {
      delete technical.smppApiConfig;
    }
    technical.protocol = getProtocol();
    if (!getIsProtocolCredentialsRequired(technical.protocol)) {
      delete technical.endpoints; //  NOTE: remove endpoints when protocol does not require them
    }
    log.debug('onSave', {customerId, contractId, productId, technical});

    const {data} = await saveProductTechnical({
      loadingText: 'Saving service technical...',
      successText: 'ServiceTechnical saved',
      variables: {customerId, contractId, productId, productTechnical: technical},
    });

    const productTechnical = data?.saveProductTechnical;
    log.info('saveServiceTechnicalMutate resolved', productTechnical);
    productTechnical && formState.onSaved(asEntity(productTechnical));

    setIsSaving(false);
  };

  //  API Credentials
  const onGenerateSecret = () => {
    const config = {
      title: 'Client Secret generation',
      content: (
        <span>
          This action will <b>invalidate the current credentials</b>, a new secret will be generated and saved as the
          valid credentials
        </span>
      ),
      onConfirm: onGenerateSecretConfirmed,
      confirmText: 'Generate new secret',
    };
    showModal(config);
  };

  const generateSecret = useMutationWrap<
    {generateSecret: ApiCredentials & {secret: string}},
    {customerId: ID; contractId: ID; productId: ID; protocol?: EndpointProtocol}
  >(gql(generateSecretMutation));
  const onGenerateSecretConfirmed = async () => {
    const protocol = getProtocol();
    log.debug('generateSecret confirmed', protocol);

    if (protocol !== EndpointProtocol.SMPP) await onSave();

    generateSecret({
      loadingText: 'Generating secret...',
      successText: 'Secret updated',
      variables: {customerId, contractId, productId, protocol},
    }).then(({data}) => {
      const {generateSecret} = data || {generateSecret: undefined};
      log.info('generateSecret resolved', generateSecret);
      if (generateSecret) {
        formState.onChange('apiCredentials.clientId')(generateSecret.clientId);
        formState.onChange('apiCredentials.secretLength')(generateSecret.secret.length);
        formState.onChange('apiCredentials.secretLastUpdated')(generateSecret.secretLastUpdated!);
        onSecretGenerated(generateSecret.secret);
      }
    });
  };

  const onSecretGenerated = (secret: string) => {
    const config = {
      title: 'Client Secret updated',
      content: (
        <span>
          The client secret has been updated. This is the only time it will be displayed. The <b>new secret</b> is:
          <div className="purpleGreyBox">{secret}</div>
        </span>
      ),
    };
    showModal(config);
  };

  //  Endpoints passwords
  const onShowEndpointPassword = (password: string) => () => {
    const config = {
      title: `Endpoint Password`,
      content: (
        <span>
          The current <b>password</b> is: <div className="purpleGreyBox">{password}</div>
        </span>
      ),
    };
    showModal(config);
  };

  const getProtocol = () => {
    return entity.protocol ? entity.protocol : entity.endpoints?.mo.protocol;
  };

  const getProtocolMessage = (protocol?: EndpointProtocol) => {
    if (protocol === EndpointProtocol.SMPP) {
      return `${protocol} protocol uses API Credentials`;
    } else {
      return '';
    }
  };

  const getIsSecretValid = (protocol?: EndpointProtocol, secretLength?: number) => {
    switch (protocol) {
      case EndpointProtocol.SMPP:
        return secretLength === 8;
      case null:
      case undefined:
        return true;
      default:
        return !!secretLength && secretLength >= 32;
    }
  };

  const getWindowSize = (
    defaultWindowSize: number,
    defaultMaxWindowSize: number,
    selectedWindowSize?: number,
    selectedMaxWindowSize?: number
  ) => {
    const windowSize = selectedWindowSize || defaultWindowSize;
    const maxWindowSize = selectedMaxWindowSize || defaultMaxWindowSize;

    return windowSize > maxWindowSize ? maxWindowSize : windowSize;
  };

  const isBelowMinWindowSize = (windowSize?: number): boolean =>
    windowSize !== undefined && windowSize !== null && windowSize < 1;

  const isAboveMaxWindowSize = (windowSize?: number): boolean => {
    const maxWindowSize = entity.smppApiConfig?.maxWindowSize
      ? entity.smppApiConfig.maxWindowSize
      : smppApiDefaultConfig?.maxWindowSize!;

    return windowSize !== undefined && windowSize !== null && maxWindowSize > 0 && windowSize > maxWindowSize;
  };

  log.debug('render', {productTypeId, productTechnical});
  const {isEditing, entity, onCancel, onChange, findError}: FormState & {entity: ProductTechnical} = formState as any;

  useEffect(() => {
    const isValid = getIsSecretValid(getProtocol(), entity.apiCredentials?.secretLength);

    if (isValid) {
      setIsSecretValidMessage('');
    } else {
      setIsSecretValidMessage('Key must be regenerated when switching between REST/SOAP and SMPP');
    }
  }, [
    entity.protocol,
    entity.endpoints?.mo.protocol,
    entity.endpoints?.dr.protocol,
    entity.apiCredentials?.secretLength,
  ]);

  const {user, hasPermission, hasBrandPermission} = useUser();
  const isTeliaAdmin = useMemo(() => hasBrandPermission(TELIA_USERS_MANAGE, '*'), [user]);
  const isEditingSmppTechnical = isTeliaAdmin && isEditing;

  const formButtons = (inPageSubtitle: boolean) => (
    <div className={classnames({'inlineBlock marginLeft': inPageSubtitle})}>
      {!isEditing ? (
        <div>
          {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && <Button text={'Edit'} className="" onClick={formState.onEdit} />}
        </div>
      ) : (
        <div>
          <Button text={'Save'} className="" onClick={onSave} kind={Button.kinds.primary} />
          <Button text={'Cancel'} className="" onClick={onCancel} kind={Button.kinds.cancel} />
        </div>
      )}
    </div>
  );

  return (
    <>
      <hr className={'horizontal-rule marginTop2'} />
      <PageSubtitle>
        Service Technical Configuration
        {formButtons(true)}
      </PageSubtitle>

      {!entity ? (
        <Loading />
      ) : (
        <>
          <section className={'form-group'}>
            <SectionTitle title={'Telia API Configuration'} />

            <FormRow>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'protocol'}
                  label="Protocol"
                  type={FieldTypes.select}
                  value={getProtocol()}
                  options={Object.values(EndpointProtocol)}
                  defaultValue={noCallbackPlaceholder}
                  isNullable={true}
                  tip={getProtocolMessage(getProtocol())}
                  onChangeAlso={(protocol) => {
                    formState.onChange('endpoints.mo.protocol')(protocol);
                    formState.onChange('endpoints.dr.protocol')(protocol);
                  }}
                />
              </FormColumn>
            </FormRow>
            {(hasProductTechnicalApiCredentials(productTypeId) || getProtocol() === EndpointProtocol.SMPP) && (
              <>
                <FormRow>
                  <FormColumn>
                    <Field
                      label="Client ID"
                      value={entity.apiCredentials?.clientId}
                      defaultValue={'Not created yet'}
                      isEditing={false}
                      onChange={onChange('apiCredentials.clientId')}
                      tip={`It's created during the first Client Secret generation`}
                      error={findError('ApiCredentials.id')} // clientId vs id?
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <Field
                      label="Client Secret"
                      type={FieldTypes.element}
                      value={
                        <Button
                          text={entity.apiCredentials?.secretLastUpdated ? 'Re-generate' : 'Generate'}
                          onClick={onGenerateSecret}
                        />
                      }
                      isEditing={false}
                      error={!isSaving ? isSecretValidMessage : ''}
                      tip={
                        <span>
                          It <b>displays only once</b> after generation
                        </span>
                      }
                    />
                    <div></div>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <Field
                      label="Client Secret Last Updated"
                      value={
                        entity.apiCredentials?.secretLastUpdated
                        //  TODO: timeAgo.ago(entity.apiCredentials.secretLastUpdated)
                      }
                      defaultValue={'NEVER'}
                      isEditing={false}
                    />
                  </FormColumn>
                </FormRow>
                {getProtocol() === EndpointProtocol.SMPP && (
                  <>
                    <FormRow>
                      <FormColumn>
                        <FieldWithFormState
                          formState={formState}
                          defaultValue={smppApiDefaultConfig?.defaultCharset}
                          entityFieldId={'smppApiConfig.defaultCharset'}
                          label="Default Charset"
                          type={FieldTypes.select}
                          tip={`Will apply default ${smppApiDefaultConfig?.defaultCharset} Default Charset if left empty`}
                          options={Object.values(DefaultCharset)}
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn>
                        <FieldWithFormState
                          formState={formState}
                          defaultValue={getWindowSize(
                            smppApiDefaultConfig?.windowSize!,
                            smppApiDefaultConfig?.maxWindowSize!,
                            entity.smppApiConfig?.windowSize,
                            entity.smppApiConfig?.maxWindowSize
                          )}
                          entityFieldId={'smppApiConfig.windowSize'}
                          label="Window Size"
                          type={FieldTypes.number}
                          tip={`Will apply default ${smppApiDefaultConfig?.windowSize} Window Size if left empty, or ${smppApiDefaultConfig?.maxWindowSize} Max Window Size if it exceeds Max Window Size`}
                          error={
                            isBelowMinWindowSize(entity.smppApiConfig?.windowSize)
                              ? 'Window Size must be higher than 0'
                              : isAboveMaxWindowSize(entity.smppApiConfig?.windowSize)
                              ? 'Window Size must not exceed Max Window Size'
                              : ''
                          }
                        />
                        <FieldWithFormState
                          formState={formState}
                          defaultValue={smppApiDefaultConfig?.maxWindowSize}
                          entityFieldId={'smppApiConfig.maxWindowSize'}
                          label="Maximum Window Size"
                          type={FieldTypes.number}
                          tip={`Will apply default ${smppApiDefaultConfig?.maxWindowSize} Max Window Size if left empty`}
                          isEditing={isEditingSmppTechnical}
                          error={
                            isBelowMinWindowSize(entity.smppApiConfig?.maxWindowSize)
                              ? 'Max Window Size must be higher than 0'
                              : ''
                          }
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn>
                        <FieldWithFormState
                          formState={formState}
                          defaultValue={smppApiDefaultConfig?.maxSessions}
                          entityFieldId={'smppApiConfig.maxSessions'}
                          label="Maximum of SMPP sessions"
                          type={FieldTypes.number}
                          tip={`Will apply default ${smppApiDefaultConfig?.maxSessions} Max Sessions if left empty`}
                          isEditing={isEditingSmppTechnical}
                        />
                      </FormColumn>
                    </FormRow>
                    {isTeliaAdmin && (
                      <>
                        <FormRow>
                          <FormColumn>
                            <FieldWithFormState
                              formState={formState}
                              defaultValue={smppApiDefaultConfig?.minReceiverSessionAlarmThreshold}
                              entityFieldId={'smppApiConfig.minReceiverSessionAlarmThreshold'}
                              label="Minimum Receiver Session Count Alarm Threshold"
                              type={FieldTypes.number}
                              tip={`Will apply default ${smppApiDefaultConfig?.minReceiverSessionAlarmThreshold} Minimum Receiver Session Count Alarm Threshold if left empty`}
                            />
                          </FormColumn>
                        </FormRow>
                        <FormRow>
                          <FormColumn>
                            <FieldWithFormState
                              formState={formState}
                              defaultValue={smppApiDefaultConfig?.minTransmitterSessionAlarmThreshold}
                              entityFieldId={'smppApiConfig.minTransmitterSessionAlarmThreshold'}
                              label="Minimum Transmitter Session Count Alarm Threshold"
                              type={FieldTypes.number}
                              tip={`Will apply default ${smppApiDefaultConfig?.minReceiverSessionAlarmThreshold} Minimum Transmitter Session Count Alarm Threshold if left empty`}
                            />
                          </FormColumn>
                        </FormRow>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </section>
          {getIsProtocolCredentialsRequired(getProtocol()) && (
            <>
              {hasProductTechnicalMo(productTypeId) && (
                <section className={'form-group'}>
                  <SectionTitle title={'Mobile Originated Callback'} />
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'endpoints.mo.protocol'}
                        isEditing={false}
                        label="Protocol"
                        defaultValue={noCallbackPlaceholder}
                        tip={getProtocolMessage(entity.endpoints?.mo.protocol)}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'endpoints.mo.endpoint'}
                        label="Endpoint"
                        placeholder={'https://...'}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'endpoints.mo.username'}
                        label="Username"
                        autocomplete={`service ${entity.id} mo username`}
                      />
                      {isEditing ? (
                        <FieldWithFormState
                          formState={formState}
                          entityFieldId={'endpoints.mo.password'}
                          label="Password"
                          type={FieldTypes.password}
                          autocomplete={`service ${entity.id} mo password`}
                        />
                      ) : (
                        <Field
                          label="Password"
                          type={FieldTypes.element}
                          value={
                            <Button
                              text={'show'}
                              isDisabled={isEditing}
                              onClick={onShowEndpointPassword(entity.endpoints?.mo.password!)}
                            />
                          }
                          isEditing={false}
                        />
                      )}
                    </FormColumn>
                  </FormRow>
                </section>
              )}
              {hasProductTechnicalDr(productTypeId) && (
                <section className={'form-group'}>
                  <SectionTitle title={'Delivery Report Callback'} />
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'endpoints.dr.protocol'}
                        label="Protocol"
                        isEditing={false}
                        defaultValue={noCallbackPlaceholder}
                        tip={getProtocolMessage(entity.endpoints?.dr.protocol)}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'endpoints.dr.endpoint'}
                        label="Endpoint"
                        placeholder={'https://...'}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'endpoints.dr.username'}
                        label="Username"
                        autocomplete={`service ${entity.id} dr username`}
                      />
                      {isEditing ? (
                        <FieldWithFormState
                          formState={formState}
                          entityFieldId={'endpoints.dr.password'}
                          label="Password"
                          type={FieldTypes.password}
                          autocomplete={`service ${entity.id} dr password`}
                        />
                      ) : (
                        <Field label="Password" type={FieldTypes.element} isEditing={false}>
                          <Button
                            text={'show'}
                            isDisabled={isEditing}
                            onClick={onShowEndpointPassword(entity.endpoints?.dr?.password as string)}
                          />
                        </Field>
                      )}
                    </FormColumn>
                  </FormRow>
                </section>
              )}
            </>
          )}
          {hasPermission(PRODUCT_FEATURES_MANAGE) && <ServiceTechnicalFeatures formState={formState} />}

          <FormRow>
            <FormColumn>{formButtons(false)}</FormColumn>
          </FormRow>
        </>
      )}
    </>
  );
};
