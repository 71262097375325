import {useLazyQuery} from '@apollo/client';
import {period} from '@telia/cpa-web-common';
import {ID, ReportSenderIdStatistic} from '@telia/cpa-web-common/dist/model';
import {Button} from '@telia/styleguide';
import gql from 'graphql-tag';
import React, {FC, useMemo} from 'react';

import senderIdStatisticsQuery from '../../../graphql/query/senderIdStatistics.graphql';

import {useCpaStatusCodes} from '../../../hooks/useCpaStatusCodes';
import {useCustomerOverviews} from '../../../hooks/useCustomerOverviews';
import {Entity, useFormState} from '../../../hooks/useFormState';
import {getLog} from '../../../log';
import Loading from '../../Loading';
import Form from '../../common/Form';
import FormColumn, {FormColumnSizeDouble} from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import {FieldTypes, FieldWithFormState} from '../../common/field';
import {ReportStatisticsTable} from './ReportStatisticsTable';

const log = getLog('ReportSenderIDStatistics', 'INFO');

interface SenderIdStatisticsQuery {
  senderIdStatistics: ReportSenderIdStatistic[];
}

export const ReportSenderIdStatistics: FC = (props) => {
  const formStateOptions = {
    isEditing: true,
    useUrlParams: true,
    preferenceFieldNames: ['startDate', 'endDate'],
  };
  const formState = useFormState(formStateOptions);
  const {entity, isEditing, putError, onChange, onSaved, onCancel, onEdit} = formState;

  const {statusCodes} = useCpaStatusCodes();
  const {getName: getCustomerName} = useCustomerOverviews();

  const senderIdStatusCodes = useMemo(
    () => statusCodes.filter(({id}) => id === '9501' || id === '9502'),
    [statusCodes]
  );

  const [fetch, {loading, data: {senderIdStatistics = undefined} = {}}] = useLazyQuery<SenderIdStatisticsQuery>(
    gql(senderIdStatisticsQuery),
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', // Doesn't check cache before making a network request
    }
  );

  const fetchStatistics = () => {
    const parameters: Entity = entity || {};

    if (!period.isValid(parameters)) {
      putError('startDate', 'Invalid search period');
      putError('endDate', 'Invalid search period');
      return;
    }

    if (!(Array.isArray(parameters?.status) && parameters.status.length > 0)) {
      putError('status', 'Please select at least one status');
      return;
    }

    log.info('Fetch statistics', {parameters});
    entity ? onSaved(entity) : onCancel();

    fetch({
      variables: {
        ...parameters,
      },
    });
  };

  const enrichedStatistics = useMemo(
    () =>
      senderIdStatistics?.map(({date, customerId, status, senderId, accessNumber, messages, segments}) => ({
        date,
        month: date.substring(0, 7),
        customerId,
        customerName: getCustomerName(customerId),
        status,
        senderId,
        accessNumber,
        messages,
        segments,
      })),
    [senderIdStatistics]
  );

  return (
    <>
      <Form>
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId="startDate"
              label="From date"
              type={FieldTypes.date}
              defaultValue="since ever"
            />
            <FieldWithFormState
              formState={formState}
              entityFieldId="endDate"
              label="To date"
              type={FieldTypes.date}
              defaultValue={entity.inheritsEndDate ? (entity.endDate as string) : 'no end'}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FieldWithFormState
            formState={formState}
            entityFieldId="status"
            label="Status"
            type={FieldTypes.multi}
            options={senderIdStatusCodes}
          />
        </FormRow>
        <FormRow>
          <FormColumn size={FormColumnSizeDouble}>
            <div id="fetchStatistics">
              {isEditing ? (
                <Button
                  onClick={fetchStatistics}
                  text={'Fetch statistics'}
                  kind={Button.kinds.primary}
                  className={'marginTop'}
                />
              ) : (
                <Button onClick={onEdit} text={'Edit search'} kind={Button.kinds.normal} className={'marginTop'} />
              )}
            </div>
          </FormColumn>
        </FormRow>
        {!isEditing && senderIdStatistics && (
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                label="Date grouping"
                formState={formState}
                entityFieldId="dateGrouping"
                type={FieldTypes.multi}
                isEditing={true}
                onChangeAlso={(dateGrouping: ID[]) => {
                  // If 'date' is unchecked also uncheck 'month'
                  if (!dateGrouping.includes('date')) {
                    onChange('dateGrouping')([]);
                  }
                }}
                options={
                  entity?.dateGrouping && (entity.dateGrouping as string[]).includes('date')
                    ? [
                        {id: 'date', name: 'Date'},
                        {id: 'month', name: 'Month'},
                      ]
                    : [{id: 'date', name: 'Date'}]
                }
              />
              <FieldWithFormState
                label="Service grouping"
                formState={formState}
                entityFieldId="serviceGrouping"
                type={FieldTypes.multi}
                isEditing={true}
                options={[
                  {id: 'customerName', name: 'Customer'},
                  {id: 'accessNumber', name: 'Access Number'},
                ]}
              />
            </FormColumn>
            <FormColumn>
              <FieldWithFormState
                label="SenderID grouping"
                formState={formState}
                entityFieldId="senderIdGrouping"
                type={FieldTypes.multi}
                isEditing={true}
                options={[
                  {id: 'senderId', name: 'Sender ID'},
                  {id: 'status', name: 'Status'},
                ]}
              />
            </FormColumn>
          </FormRow>
        )}
      </Form>
      {loading && <Loading />}
      {!isEditing && enrichedStatistics && (
        <ReportStatisticsTable
          loading={loading}
          statistics={enrichedStatistics}
          groupBy={
            entity &&
            ([] as ID[]).concat(
              (entity.dateGrouping as ID[])?.includes('month')
                ? ([...(entity.dateGrouping as ID[])].remove('date') as ConcatArray<ID>)
                : (entity.dateGrouping as ConcatArray<ID>),
              entity.serviceGrouping as ConcatArray<ID>,
              entity.senderIdGrouping as ConcatArray<ID>
            )
          }
        />
      )}
    </>
  );
};
