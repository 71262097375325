import {Tooltip} from '@telia/styleguide';
import React, {FC, useState} from 'react';

import {ID} from '../../model';
import FormColumn, {FormColumnSizeHalf, FormColumnSizeNormal} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {ToggleSwitch, ToggleSwitchState} from '../common/ToggleSwitch';
import {SmppSummaryTables} from '../management/SmppSummaryTables';
import SmppConnectionsTable from './SmppConnectionsTable';

interface SmppConnectionsProps {
  customerId?: ID;
}

export const SmppConnections: FC<SmppConnectionsProps> = ({customerId}) => {
  const [active, setActive] = useState<ToggleSwitchState>(ToggleSwitchState.LEFT);

  return (
    <Page>
      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <PageSubtitle>
            {active === ToggleSwitchState.RIGHT || customerId ? (
              <>
                Smpp connections
                <Tooltip text="Click on cell to view full value" />
              </>
            ) : (
              active === ToggleSwitchState.LEFT && <>Smpp summary</>
            )}
          </PageSubtitle>
        </FormColumn>
        {!customerId && (
          <FormColumn size={FormColumnSizeHalf}>
            <ToggleSwitch
              active={active}
              leftLabel="Summary view"
              rightLabel="Detailed view"
              leftOnClickHandler={() => setActive(ToggleSwitchState.LEFT)}
              rightOnClickHandler={() => setActive(ToggleSwitchState.RIGHT)}
            />
          </FormColumn>
        )}
      </FormRow>

      {active === ToggleSwitchState.RIGHT || customerId ? (
        <SmppConnectionsTable customerId={customerId} />
      ) : (
        active === ToggleSwitchState.LEFT && <SmppSummaryTables />
      )}
    </Page>
  );
};
