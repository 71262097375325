import {urlPaths} from '@telia/cpa-web-common';
import {Container} from '@telia/styleguide';
import AllIcons from '@telia/styleguide/assets/allicons/AllIcons';
import React, {FC, useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import * as AppRoutes from './appRoutes';
import ErrorBoundary from './components/ErrorBoundary';
import {HomeFc} from './components/Home';
import Loading from './components/Loading';
import {Support} from './components/Support';
import {PathBrandGuard} from './components/common/PathBrandGuard';
import {DocumentsMainFc} from './components/documents/DocumentsMain';
import {BrandFc} from './components/header/Brand';
import {HeaderFc} from './components/header/Header';
import {ManagementFc} from './components/management/Management';
import {RerouterFc} from './components/management/Rerouter';
import {CustomerFc} from './components/provisioning/Customer';
import {CustomerList} from './components/provisioning/CustomerList';
import {ServiceRedirect} from './components/provisioning/ServiceRedirect';
import {ReportMainFc} from './components/report/ReportMain';
import {SettlementMainFc} from './components/settlement/SettlementMain';
import {MyPageFc} from './components/user/MyPage';
import {RequestAccess} from './components/user/RequestAccess';
import {useUser} from './hooks/useUser';
import {getLog} from './log';
import {UserAccessStatusType} from './model';

const log = getLog('App', 'INFO');

export const AppFc: FC = (_) => {
  const location = useLocation();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {user} = useUser();

  const LoginFc = () => {
    useEffect(() => {
      const loginUri = `${urlPaths.LOGIN}${location.search}`;
      log.info('Redirect browser (backend GET) to: ', loginUri);
      window.location.href = loginUri; /*hit the backend*/
    });
    return <Loading />;
  };
  log.debug('render', location);

  return (
    <>
      <HeaderFc />
      <AllIcons />
      <Container size={'large'} className="form--compact">
        <ErrorBoundary key={location.pathname}>
          <Routes>
            <Route element={<PathBrandGuard />}>
              <Route
                path={AppRoutes.PROVISIONING}
                element={<Navigate replace to={formatWithBrand(AppRoutes.PROVISIONING_LIST)} />}
              />
              {/* Use ServiceRedirect for both legacy route (with "product" in the url) and new (with "service" in the url) */}
              {[AppRoutes.PROVISIONING_PRODUCT__productId, AppRoutes.PROVISIONING_SERVICE__productId].map((path) => (
                <Route path={path} element={<ServiceRedirect />} />
              ))}
              <Route path={AppRoutes.PROVISIONING_LIST} element={<CustomerList />} />
              <Route path={AppRoutes.PROVISIONING_CUSTOMER__customerId + '/*'} element={<CustomerFc />} />
              <Route path={AppRoutes.SETTLEMENT + '/*'} element={<SettlementMainFc />} />
              <Route path={AppRoutes.REPORT + '/*'} element={<ReportMainFc />} />
              <Route path={AppRoutes.MANAGEMENT + '/*'} element={<ManagementFc />} />
            </Route>
            <Route path={AppRoutes.DOCUMENTS} element={<DocumentsMainFc />} />
            <Route path={AppRoutes.SUPPORT} element={<Support />} />
            <Route path={AppRoutes.USER} element={<MyPageFc />} />
            <Route path={AppRoutes.LOGIN} element={<LoginFc />} />
            <Route path={AppRoutes.BRAND__brandId} element={<BrandFc />} />
            <Route path={AppRoutes.HOME} element={<HomeFc />} />

            <Route
              path={AppRoutes.REQUEST_ACCESS}
              element={
                <RerouterFc
                  isDisplayed={!user || user.userAccessStatusType === UserAccessStatusType.APPLYING}
                  navigateTo={AppRoutes.HOME}
                >
                  <RequestAccess />
                </RerouterFc>
              }
            />
          </Routes>
        </ErrorBoundary>
      </Container>
    </>
  );
};
