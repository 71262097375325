import {Dict, IdAndName} from '@telia/cpa-web-common/dist/model';

import {getLog} from '../log';

const log = getLog('useCpaStatusCodes', 'INFO');

export interface UseCpaStatusCodes {
  getStatusDescription: (code: number) => string;
  statusCodes: IdAndName[];
}

const getStatusDescription: (code: number) => string = (code) => {
  log.debug('cpaStatusCodeDict[code]: ' + cpaStatusCodeDict[code], {code, cpaStatusCodeDict});
  return cpaStatusCodeDict[code] ? cpaStatusCodeDict[code] : `${code}`;
};

export const useCpaStatusCodes: () => UseCpaStatusCodes = () => {
  return {getStatusDescription, statusCodes};
};

const cpaStatusCodeDict: Dict<string> = {
  // Success
  '200': 'Success',

  // Integrity errors
  '9001': 'Duplicate client reference',

  // Input errors
  '9101': 'Invalid destination address',
  '9102': 'Invalid originating address',
  '9103': 'Invalid price',
  '9104': 'Invalid large account',
  '9105': 'Invalid number series',
  '9106': 'Invalid service category/type',
  '9107': 'Invalid invoice text',

  // Subscriber errors
  '9201': 'Subscriber unknown',
  '9202': 'Subscriber temporarily barred',
  '9203': 'Subscriber permanently barred',
  '9204': 'Subscriber reserved',
  '9205': 'Subscriber too young',
  '9206': 'Illegal operator',
  '9207': 'Refused by service provider',

  // Prepaid and usage limit errors
  '9301': 'Insufficient balance',
  '9302': 'Subscriber has exceeded usage limit',
  '9303': 'Subscriber is prepaid',

  // Payload errors
  '9401': 'Invalid payload',

  // Reserved OA
  '9501': 'Reserved Sender ID',
  '9502': 'Blocked Sender ID look-a-like',

  // Other errors
  '9901': 'Unspecified error',
  '9902': 'Throttled - status rate exceeded',
  '9903': 'Invalid validity period',
  '9904': 'Message queue full',
  '9999': 'Expired',

  //  Notify errors
  '10105': 'Missing endpoint configuration',
  '10103': 'Endpoint error',
  '10101': 'Endpoint connection error',
};

const statusCodes: IdAndName[] = Object.keys(cpaStatusCodeDict)
  .filter((key) => key.length < 5)
  .map((key) => ({
    id: key,
    name: `${key}: ${getStatusDescription(parseInt(key))}`,
  }));
