import {ToggleSwitch as TeliaToggleSwitch} from '@telia/styleguide';
import {ToggleSwitchProps} from '@telia/styleguide/atoms/ToggleSwitch/ToggleSwitch';
import React, {FC} from 'react';

import './ToggleSwitch.scss';

export enum ToggleSwitchState {
  LEFT = 'left',
  RIGHT = 'right',
}

export const ToggleSwitch: FC<ToggleSwitchProps> = (props) => {
  return <TeliaToggleSwitch {...props} />;
};
