import React, {FC} from 'react';

import './Support.scss';

import * as AppRoutes from '../appRoutes';
import {getLog} from '../log';
import PageTitle from './common/PageTitle';

const log = getLog('Support', 'INFO');

interface SupportContact {
  country: string;
  email: string;
}
export const Support: FC = () => {
  const supportContacts: SupportContact[] = [
    {country: 'Norway', email: 'cpa-support@telia.no'},
    {country: 'Sweden', email: 'bulk-messaging@telia.se'},
  ];

  log.debug('render');

  return (
    <div>
      <PageTitle title="Support" />
      <p>For questions related to the Telia Bulk Messaging product or this portal you can contact:</p>
      {supportContacts.map(({country, email}) => (
        <p className={'support__contact-link'}>
          {country}
          <div>
            <a className="link support__link" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        </p>
      ))}

      <p>
        More details are found in the Bulk Messaging agreement appendix 2, found in the{' '}
        <a className="link support__link" href={AppRoutes.DOCUMENTS}>
          Documents
        </a>{' '}
        page
      </p>
    </div>
  );
};
