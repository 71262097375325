import React, {FC} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import * as AppRoutes from '../../../appRoutes';
import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {ALL_CUSTOMERS_READ} from '../../../permissions';
import FormColumn, {FormColumnSizeNormal} from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import PageSubtitle from '../../common/PageSubtitle';
import {ToggleSwitch} from '../../common/ToggleSwitch';
import {ReportDeliveryStatistics} from './ReportDeliveryStatistics';
import {ReportSenderIdStatistics} from './ReportSenderIdStatistics';

const log = getLog('Statistics', 'INFO');

export const ReportStatistics: FC = (props) => {
  const {hasPermission} = useUser();

  const subPath = AppRoutes.useSubPath(AppRoutes.REPORT_STATISTICS);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const statisticsDeliveryPath = formatWithBrand(AppRoutes.REPORT_STATISTICS_DELIVERY);
  const statisticsSenderIdPath = formatWithBrand(AppRoutes.REPORT_STATISTICS_SENDERID);

  return (
    <div className={'form--column--margin--normal'}>
      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <PageSubtitle subtitle="Detailed Statistics" />
        </FormColumn>
        {hasPermission(ALL_CUSTOMERS_READ) && (
          <FormColumn>
            <ToggleSwitch
              {...(pathname === statisticsDeliveryPath && {active: 'left'})}
              {...(pathname === statisticsSenderIdPath && {active: 'right'})}
              leftLabel="Delivery Statistics"
              rightLabel="Blocked SenderIDs"
              leftOnClickHandler={() => navigate(statisticsDeliveryPath, {replace: true})}
              rightOnClickHandler={() => navigate(statisticsSenderIdPath, {replace: true})}
            />
          </FormColumn>
        )}
      </FormRow>
      <Routes>
        <Route index element={<Navigate replace to={statisticsDeliveryPath} />} />
        <Route path={subPath(AppRoutes.REPORT_STATISTICS_DELIVERY)} element={<ReportDeliveryStatistics />} />
        <Route path={subPath(AppRoutes.REPORT_STATISTICS_SENDERID)} element={<ReportSenderIdStatistics />} />
      </Routes>
    </div>
  );
};
